import { flatten } from 'flat';

export * from './stringHelpers';
export * from './typeHelpers';
export * from './urlHelpers';

export const isSSR = () => typeof window === 'undefined';

export const flattenPropertiesAsStrings = <
  T extends { [key: string | number]: unknown } | null | undefined,
>(
  obj: T
) => {
  if (!obj) {
    return {};
  }

  return propertiesAsStrings(
    flatten(obj) as { [key: string | number]: unknown }
  );
};

export const propertiesAsStrings = <
  T extends { [key: string | number]: unknown } | null | undefined,
>(
  source: T,
  excludeProperties: string[] = []
): { [key: string]: string } => {
  if (!source) {
    return {};
  }

  return Object.keys(source).reduce(
    (acc: { [key: string]: string }, key: string) => {
      const value = source[key];

      if (excludeProperties.includes(key)) {
        return acc;
      }

      if (typeof value === 'string') {
        acc[key] = value;
      }

      if (typeof value === 'number' || typeof value === 'boolean') {
        acc[key] = value.toString();
      }

      return acc;
    },
    {}
  );
};

export const mapArrayToBooleanFlags = <T>(
  array: T[] | undefined | null,
  prefix: string,
  property: keyof T
): Record<string, string> => {
  if (!array) return {};

  return array.reduce(
    (acc, item) => ({
      ...acc,
      [`${prefix}_${item[property]}`]: 'true',
    }),
    {} as Record<string, string>
  );
};
