import { css } from '@emotion/react';

export const getCssVariables = () => css`
  --color-black: #000;
  --color-white: #fff;
  --color-primary-5: #241e3d;
  --color-primary-20: #302a47;
  --color-primary-30: #413a5c;
  --color-primary-35: #544a79;
  --color-primary-40: #685b97;
  --color-primary-50: #7466a8;
  --color-primary-55: #7a6da7;
  --color-primary-60: #8578b2;
  --color-primary-70: #b1a8d3;
  --color-primary-80: #c5bede;
  --color-primary-90: #d7d3e8;
  --color-secondary-0: var(--color-black);
  --color-secondary-40: #1a1726;
  --color-secondary-50: #34323d;
  --color-secondary-60: #54535b;
  --color-secondary-70: #86848e;
  --color-secondary-80: #b4b2bb;
  --color-secondary-85: #c7c5cc;
  --color-secondary-90: #dad9de;
  --color-secondary-93: #e3e1e9;
  --color-secondary-95: #efeef3;
  --color-secondary-97: #f7f6fc;
  --color-secondary-98: #fafafd;
  --color-tertiary-30: #f5e400;
  --color-tertiary-40: #ffed00;
  --color-tertiary-50: #fff466;
  --color-tertiary-60: #fff9a6;
  --color-quaternary-5: #3c3a35;
  --color-quaternary-30: #d8cebc;
  --color-quaternary-40: #f0e6d4;
  --color-quaternary-50: #f3efe6;
  --color-positive-70: #c9edc2;
  --color-error-40: #d9001a;
  --font-family-open-sans: 'Open Sans', sans-serif;
  --font-family-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  --font-family-primary: 'COCOGOOSE', sans-serif;
  --font-family-primary-light: 'Cocogoose Pro Light', sans-serif;
  --font-family-primary-semilight: 'Cocogoose Pro Semilight', sans-serif;
  --font-family-primary-bold: 'Cocogoose Pro Darkmode', sans-serif;
  --line-height-12: 0.75rem;
  --line-height-14: 0.875rem;
  --line-height-16: 1rem;
  --line-height-18: 1.125rem;
  --line-height-20: 1.25rem;
  --line-height-22: 1.375rem;
  --line-height-24: 1.5rem;
  --line-height-26: 1.625rem;
  --line-height-28: 1.75rem;
  --line-height-30: 1.875rem;
  --line-height-36: 2.25rem;
  --line-height-38: 2.375rem;
  --line-height-52: 3.25rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-22: 1.375rem;
  --font-size-28: 1.75rem;
  --font-size-32: 2rem;
  --font-size-42: 2.625rem;
  --spacing-2: 0.125rem;
  --spacing-4: 0.25rem;
  --spacing-8: 0.5rem;
  --spacing-12: 0.75rem;
  --spacing-16: 1rem;
  --spacing-24: 1.5rem;
  --spacing-32: 2rem;
  --spacing-40: 2.5rem;
  --spacing-48: 3rem;
  --spacing-64: 4rem;
  --spacing-80: 5rem;
  --spacing-96: 6rem;
  --spacing-128: 8rem;
  --spacing-160: 10rem;
  --letter-spacing: -0.16%;
  --icon-size-s: var(--spacing-16);
  --icon-size-m: var(--spacing-24);
  --icon-size-l: var(--spacing-32);
  --font-weight-bold: 400;
  --font-link-s: var(--font-size-12) / var(--line-height-12)
    var(--font-family-primary-light);
  --font-link-l: var(--font-size-14) / var(--line-height-18)
    var(--font-family-primary-light);
  --font-link-xl: var(--font-size-16) / var(--line-height-24)
    var(--font-family-primary-light);
  --font-preamble-l: var(--font-size-20) / var(--line-height-26)
    var(--font-family-primary-semilight);
  --font-preamble-s: var(--font-size-16) / var(--line-height-22)
    var(--font-family-primary-semilight);
  --font-body-s-bold: var(--font-weight-bold) var(--font-size-12) /
    var(--line-height-16) var(--font-family-primary-bold);
  --font-body-s-light: var(--font-size-12) / var(--line-height-16)
    var(--font-family-primary-light);
  --font-body-m-bold: var(--font-weight-bold) var(--font-size-14) /
    var(--line-height-18) var(--font-family-primary-bold);
  --font-body-m-light: var(--font-size-14) / var(--line-height-18)
    var(--font-family-primary-light);
  --font-body-l-bold: var(--font-weight-bold) var(--font-size-16) /
    var(--line-height-24) var(--font-family-primary-bold);
  --font-body-l-light: var(--font-size-16) / var(--line-height-24)
    var(--font-family-primary-light);
  /* TODO: Open Sans equivalent according to Figma */
  --font-body-l-light-open-sans: 400 var(--font-size-16) / var(--line-height-24)
    var(--font-family-open-sans);

  --font-body-xl-bold: var(--font-weight-bold) var(--font-size-16) /
    var(--line-height-24) var(--font-family-primary-bold);
  --font-body-xl-light: var(--font-size-18) / var(--line-height-30)
    var(--font-family-primary-light);
  --font-label-s-bold: var(--font-weight-bold) var(--font-size-12) /
    var(--line-height-12) var(--font-family-primary-bold);
  --font-label-s-light: var(--font-size-12) / var(--line-height-12)
    var(--font-family-primary-light);
  --font-label-l-bold: var(--font-weight-bold) var(--font-size-14) /
    var(--line-height-14) var(--font-family-primary-bold);

  /* TODO: Open Sans equivalent according to Figma */
  --font-label-l-bold-open-sans: 700 var(--font-size-16) / var(--line-height-18)
    var(--font-family-open-sans);

  --font-label-l-light: var(--font-size-14) / var(--line-height-14)
    var(--font-family-primary-light);
  --font-label-xl-bold: var(--font-weight-bold) var(--font-size-16) /
    var(--line-height-16) var(--font-family-primary-bold);
  --font-label-xl-light: var(--font-size-16) / var(--line-height-16)
    var(--font-family-primary-light);
  --font-label-xxl-bold: var(--font-weight-bold) var(--font-size-20) /
    var(--line-height-20) var(--font-family-primary-bold);
  --font-label-xxl-light: var(--font-size-20) / var(--line-height-20)
    var(--font-family-primary-light);
  --font-heading-5: var(--font-weight-bold) var(--font-size-18) /
    var(--line-height-24) var(--font-family-primary-bold);
  --font-heading-4: var(--font-weight-bold) var(--font-size-22) /
    var(--line-height-28) var(--font-family-primary-bold);
  --font-heading-3: var(--font-weight-bold) var(--font-size-28) /
    var(--line-height-36) var(--font-family-primary-bold);
  --font-heading-2: var(--font-weight-bold) var(--font-size-32) /
    var(--line-height-38) var(--font-family-primary-bold);
  --font-heading-1: var(--font-weight-bold) var(--font-size-42) /
    var(--line-height-52) var(--font-family-primary-bold);
  --font-caption-l-light: var(--font-size-12) / var(--line-height-16)
    var(--font-family-primary-light);
  --font-caption-l-bold: var(--font-weight-bold) var(--font-size-12) /
    var(--line-height-16) var(--font-family-primary-bold);
  --border-radius-s: 0.25rem;
  --border-radius-m: 0.5rem;
  --border-radius-l: 0.75rem;
  --border-radius-xl: 1rem;
  --border-radius-xxl: 2rem;

  --body-background: var(--color-quaternary-50);
  --creator-banner-background: var(--color-white);
  --card-pill-background: var(--color-quaternary-40);
  --button-primary-default-background: var(--color-secondary-40);
  --button-primary-default-foreground: var(--color-white);
  --button-primary-hover-background: var(--color-secondary-50);
`;

export const getFontFaces = () => css`
  @font-face {
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap')
      format('woff2');
    font-family: 'Open Sans Regular', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: 'wdth' 100;
  }

  @font-face {
    font-family: 'COCOGOOSE';
    src: url('https://cdn.sanity.io/files/inntox6v/production/2ef4dd6ce8092146e0afae4e11ab55dbc481ad0a.otf/Cocogoose-Pro-Regular.otf')
      format('opentype');
  }

  @font-face {
    font-family: 'Cocogoose Pro Darkmode';
    src:
      url('https://cdn.sanity.io/files/inntox6v/production/cb0acd973f40aa4e72252f6e16cf1a6c6b9ee82b.woff2/CocogoosePro-Darkmode.woff2')
        format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/ad8f81bae1e42af558a7b1e49fa5e7579dad8db1.woff/CocogoosePro-Darkmode.woff')
        format('woff'),
      url('https://cdn.sanity.io/files/inntox6v/production/bdc647dbedd727ad2ca8c267e51d355949e79392.svg/CocogoosePro-Darkmode.svg')
        format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cocogoose Pro Semilight';
    src:
      url('https://cdn.sanity.io/files/inntox6v/production/0fd426dd95f586f6fa51b810d9c4ca060d88f834.woff2/CocogoosePro-Semilight.woff2')
        format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/0303fab577cc7c8f971f9fd31839d940fa01f0a2.woff/CocogoosePro-Semilight.woff')
        format('woff'),
      url('https://cdn.sanity.io/files/inntox6v/production/522d4793d87cb2090f19ffeeef2b476df25c8466.svg/CocogoosePro-Semilight.svg')
        format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cocogoose Pro Light';
    src:
      url('https://cdn.sanity.io/files/inntox6v/production/f8a2e25e3698e50e3590f79ab0eaf8dddd28bbf7.woff2/CocogoosePro-Light.woff2')
        format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/251d15dbecae5edb1cf8b985c34d88ddaa0aa5e0.woff/CocogoosePro-Light.woff')
        format('woff'),
      url('https://cdn.sanity.io/files/inntox6v/production/537d48520a7e903511c43223d9e2aae0b0e994d6.svg/CocogoosePro-Light.svg')
        format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cocogoose Pro';
    src:
      url('https://cdn.sanity.io/files/inntox6v/production/3d236b80db73f13f4a20fa13762755ec34f10c84.woff2/CocogoosePro-Regular.woff2')
        format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/0970c760da86f4c9428770e3b6cc8b2a4b093f5e.woff/CocogoosePro-Regular.woff')
        format('woff'),
      url('https://cdn.sanity.io/files/inntox6v/production/84a84da87920cc07a66183e0982a8dbb45280aa0.svg/CocogoosePro-Regular.svg')
        format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;
