import { SerializedStyles } from '@emotion/react';
// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle } from 'styled-components';

import { getCssVariables, getFontFaces } from '../../../styles/CSSVariables';

// this file is only here because styled-components injects styles differently than emotion
// it puts the styles in document -> head, and @font-face only works when you put them there
// in emotion, the styles get injected inside the shadow root, and there @font-face doesn't work
//
// I'm choosing not to migrate this to emotion, because we probably want to migrate away from this setup
// for web components and use the new saved-trips-component setup using Vite instead.

const extractEmotionCssString = (emotionCss: SerializedStyles) => {
  return emotionCss.styles;
};

const GlobalStyles = createGlobalStyle`
  share-component, book-component, book-component-type-1, book-component-type-2, saved-trips-component {
    ${extractEmotionCssString(getCssVariables())}
  }

  ${extractEmotionCssString(getFontFaces())}
`;
export default GlobalStyles;
